var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.jobOrderStage.jobOrderStageImagePath,
            _vm.jobOrderStage.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.jobOrderStage.jobOrderStageImageIsDefault &&
          _vm.checkPrivilege(_vm.hasJobOrderStageDeleteImage())},on:{"changeValue":function($event){_vm.jobOrderStage.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.jobOrderStage.fullCode,"title":_vm.$t('JobOrderStages.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.jobOrderStage.fullCode = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-jobOrderStageNameAr"),"errors":_vm.errors_jobOrderStageNameAr,"value":_vm.jobOrderStage.jobOrderStageNameAr,"title":_vm.$t('JobOrderStages.nameAr'),"imgName":'jobOrderStages.svg'},on:{"changeValue":function($event){_vm.jobOrderStage.jobOrderStageNameAr = $event;
            _vm.$v.jobOrderStage.jobOrderStageNameAr.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-jobOrderStageNameEn"),"errors":_vm.errors_jobOrderStageNameEn,"value":_vm.jobOrderStage.jobOrderStageNameEn,"title":_vm.$t('JobOrderStages.nameEn'),"imgName":'jobOrderStages.svg'},on:{"changeValue":function($event){_vm.jobOrderStage.jobOrderStageNameEn = $event;
            _vm.$v.jobOrderStage.jobOrderStageNameEn.$touch();}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-jobOrderStageNotes"),"value":_vm.jobOrderStage.jobOrderStageNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.jobOrderStage.jobOrderStageNotes = $event}}}),_vm._v(" "),_c('CustomCheckbox',{attrs:{"className":'col-md-6',"value":_vm.jobOrderStage.mustAttachFile,"title":_vm.$t('JobOrderStages.mustAttachFile')},on:{"changeValue":function($event){_vm.jobOrderStage.mustAttachFile = $event}}}),_c('CustomCheckbox',{attrs:{"className":'col-md-6',"value":_vm.jobOrderStage.mustWriteDescription,"title":_vm.$t('JobOrderStages.mustWriteDescription')},on:{"changeValue":function($event){_vm.jobOrderStage.mustWriteDescription = $event}}})],1)]),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }